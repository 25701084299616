:root {
  --img-box-aspect-ratio: 100%;
  --img-box-icon-padding-base: 18;
  --img-box-bg: #{mix($px-dark, white, 20%)};
  --img-box-color: #{mix($px-dark, white, 50%)};
}
.quickSight,
.actionList {
  --img-box-icon-padding-base: 25;
  --img-box-bg: var(--module-color);
  --img-box-color: rgba(var(--bs-white-rgb), 0.75);
}
.img-box-aspect-ratio-65 {
  --img-box-aspect-ratio: 65%;
}
.img-box {
  max-width: 100%;
  background: var(--img-box-bg);
  color: var(--img-box-color);
  .img-box-inner {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    --bs-aspect-ratio: var(--img-box-aspect-ratio);
  }
  .icon {
    width: 100%;
    display: block;
    height: 100%;
    color: inherit;
    padding: calc(
      var(--img-box-icon-padding-base) * var(--img-box-aspect-ratio) / 100
    );
    &.error-icon {
      color: rgba(220, 53, 69, 0.15);
    }
  }
  .img-box-img {
    color: #999;
    font-size: 0.75em;
    transition: opacity 0.2s;
    &:before {
      display: block;
      padding: 0.5rem;
      transition: opacity 0.2s;
      opacity: 0;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  &.is-loading {
    position: relative;
    .loader {
      position: absolute;
      inset: 0;
    }
    .img-box-img {
      color: transparent;
      opacity: 0;
      &:before {
        display: none;
      }
    }
  }
  &.has-error {
    position: relative;
    .icon {
      position: absolute;
      inset: 0;
    }
    .img-box-img {
      &:before {
        color: mix($danger, white, 80%);
      }
    }
  }
  &.input-border {
    border-color: #ced4da;
  }
}
