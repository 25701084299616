.px-logo {
  color: inherit;
  svg {
    width: 1.69em;
    height: auto;
    display: inline-block;
    margin-top: -0.25em;
    vertical-align: middle;
    overflow: visible;
  }
  .logo-text {
    font-size: 140%;
    display: inline-block;
    vertical-align: -7%;
    font-weight: 300;
    margin-left: 0.3em;
    white-space: nowrap;
    line-height: 1;
  }
  sup {
    font-weight: 600;
    font-size: 50%;
    margin-left: 0.2em;
    top: -0.8em;
    opacity: 0.7;
  }
  &.white-highlight {
    color: rgba(255, 255, 255, 0.8);
    svg,
    sup {
      color: $px-hl;
    }
  }
  &.stacked {
    svg {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 6em;
      margin-bottom: 0.1em;
      opacity: 0.95;
      &.btn-icon {
        display: none;
      }
    }
  }
  &.in-circle {
    svg {
      background: $px-dark;
      border-radius: 100%;
      color: $px-hl;
      padding: 0.5em;
      &.btn-icon {
        display: none;
      }
    }
  }
  &.text-only {
    svg {
      display: none;
    }
    .logo-text {
      margin-left: 0;
    }
  }
  &.icon-only {
    .logo-text {
      display: none;
    }
  }
}
