.form-floating,
.form-control,
.form-select {
  --bs-body-bg: var(--bs-light);
  --bs-body-bg-rgb: var(--bs-light-rgb);
  --bs-border-color: #f8f9fa;
  &[disabled] {
    border-color: var(--bs-gray-400);
  }
}
.input-group-sm .form-select,
.form-select-sm {
  background-position: right 0.3rem center;
  padding-right: 1.5rem;
}
.form-floating > label {
  color: #777;
}
.form-floating > label::after {
  background-color: transparent !important;
}

input[type="checkbox"]:not(:disabled),
input[type="radio"]:not(:disabled) {
  cursor: pointer;
}

.form-control.h-auto {
  height: auto;
}

.form-floating {
  height: 100%;
  .stretch-height {
    min-height: 100%;
  }
}
.min-width-input {
  min-width: 110px;
}
.min-width-select {
  min-width: 200px;
}
.form-check .invalid-feedback,
.form-check .valid-feedback {
  @extend .mt-0;
}
.form-check-inline {
  .invalid-feedback,
  .valid-feedback {
    display: inline;
  }
}

.match-floating-input {
  padding: 0;
  line-height: 1.25;
  // background-color: var(--bs-body-bg);
  // border-radius: var(--bs-border-radius-sm);
  // border: 1px solid var(--bs-border-color);
  min-height: 58px;
  .inner:not(.p-0) {
    padding: 0.6rem 0.75rem;
  }
}
