// module colors
:root {
  --dashboard: var(--bs-px-hl);
  --dashboard-rgb: var(--bs-px-hl-rgb);
  --customer: rgb(227, 149, 0);
  --customer-rgb: 227, 149, 0;
  // --order: rgb(227, 87, 0);
  --order: rgb(229, 104, 116);
  --order-rgb: 229, 104, 116;
  --variant: rgb(164, 83, 142);
  --variant-rgb: 164, 83, 142;
  --campaign: var(--bs-purple);
  --campaign-rgb: var(--bs-purple-rgb);
  --cycle: var(--bs-green);
  --cycle-rgb: var(--bs-green-rgb);
  --quickSight: var(--bs-info);
  --quickSight-rgb: var(--bs-info-rgb);
  --actionList: var(--bs-teal);
  --actionList-rgb: var(--bs-teal-rgb);

  --module-color: var(--bs-secondary);
  --module-color-rgb: var(--bs-secondary-rgb);
}

.dashboard {
  --module-color: var(--dashboard);
  --module-color-rgb: var(--dashboard-rgb);
}
.customer {
  --module-color: var(--customer);
  --module-color-rgb: var(--customer-rgb);
}
.order {
  --module-color: var(--order);
  --module-color-rgb: var(--order-rgb);
}
.variant {
  --module-color: var(--variant);
  --module-color-rgb: var(--variant-rgb);
}
.campaign {
  --module-color: var(--campaign);
  --module-color-rgb: var(--campaign-rgb);
}
.cycle {
  --module-color: var(--cycle);
  --module-color-rgb: var(--cycle-rgb);
}
.quickSight {
  --module-color: var(--quickSight);
  --module-color-rgb: var(--quickSight-rgb);
}
.actionList {
  --module-color: var(--actionList);
  --module-color-rgb: var(--actionList-rgb);
}
