// Side menu
.nav-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 1030;
  @include media-breakpoint-down(xl) {
    &.isOpen {
      display: block;
    }
  }
}
.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1031;
  display: flex;
  flex-direction: column;
  background: tint-color(desaturate($px-dark, 80%), 5%);
  justify-content: space-between;
  width: var(--side-menu-width);
  transition: width 0.3s, margin-left 0.3s;
  .logo {
    transition: background 0.2s;
    padding: 1em;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    height: 56px;
    width: 100%;
    .icon-wrap {
      width: 27px;
      height: 27px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      svg {
        position: absolute;
        inset: 0;
        transition: opacity 0.1s, transform 0.2s;
      }
      .logo-icon {
        opacity: 1;
      }
      .btn-icon {
        opacity: 0;
        display: inline-block;
      }
    }
    &:hover,
    &:active {
      background: rgba(var(--bs-dark-rgb), 0.9);
      .logo-icon {
        opacity: 0;
        transform: rotate(180deg);
      }
      .btn-icon {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
  }
  .nav-inner {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: -1px;
    &.no-b-t {
      border-top: none;
      margin-top: 0;
    }
    .btn-link {
      background: none;
      border: none;
      outline: none;
      text-align: left;
    }
    a,
    .btn-link {
      text-decoration: none;
      display: block;
      padding: 1em 1.2em;
      line-height: 1.3;
      color: var(--bs-gray-500);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      transition: background 0.2s, color 0.2s;
      white-space: nowrap;
      user-select: none;
      &.bordered {
        border-right: 10px solid;
      }
      &.sub-menu-item {
        padding: 0.65em 1.2em 0.65em 2.2em;
        background: hsla(0, 0%, 0%, 0.2);
      }
      &.no-b-b {
        border-bottom: none;
      }
      svg {
        font-size: 1.3em;
        overflow: visible;
        vertical-align: text-bottom;
      }
      .link-text {
        font-size: 0.9em;
        margin-left: 0.25em;
        pointer-events: none;
      }
      &:hover {
        --bs-bg-opacity: 0.85;
        background: rgba(var(--module-color-rgb), var(--bs-bg-opacity));
        color: var(--bs-light);
        svg {
          color: rgba(var(--bs-light-rgb), 0.8);
        }
      }
      &.active {
        background: rgba(var(--module-color-rgb), var(--bs-bg-opacity));
        color: var(--bs-light);
        svg {
          color: rgba(var(--bs-light-rgb), 0.8);
        }
      }
    }
    &.colored-links {
      --bs-bg-opacity: 0.8;
      a:not(:hover):not(.active),
      .btn-link:not(:hover):not(.active) {
        svg {
          color: var(--module-color);
        }
      }
    }
    .customer.active {
      --bs-bg-opacity: 0.9;
    }
  }
  .link-text,
  .link-logo {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.2s, transform 0.1s;
  }
  &:not(.isOpen) {
    width: var(--collapsed-menu-width);
    .link-text,
    .logo-text {
      opacity: 0;
      transform: translateX(-200px);
    }
  }
  @include media-breakpoint-down(sm) {
    margin-left: calc(-1 * var(--collapsed-menu-width));
    &.isOpen {
      margin-left: 0;
    }
  }
}
