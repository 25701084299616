// Dark theme styles for bootstrap components
// incl. offcanvas, list groups, forms

@mixin dark-theme {
  &.offcanvas {
    --bs-body-bg: var(--subtle-dark);
    --bs-body-bg-rgb: 39, 48, 52;
  }
  .form-floating {
    --bs-body-bg: var(--bs-gray-900);
    --bs-body-bg-rgb: 33, 37, 41;
  }

  // --bs-light: rgb(168, 168, 168);
  // --bs-light-rgb: 168, 168, 168;
  .link-light,
  .link-warning,
  .link-info {
    --bs-link-opacity: 0.6;
  }
}

// Apply dark theme
.bs-dark,
[data-bs-theme="dark"] {
  @include dark-theme;
}
