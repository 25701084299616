// Delays

:root {
  --duration: 0.2s;
  // --delay-step: 0.2;
  // --delay-i: 0;
  --delay: 0s;
}

$short-animation-duration-unit: 0.1;
$animation-duration-unit: 0.2;
@for $i from 1 through 10 {
  .short-delay-#{$i},
  .short-delay-each-child > :nth-child(#{$i}),
  .short-delay-parent .delay:nth-child(#{$i}) {
    // animation-delay: #{$i * $short-animation-duration-unit}s;
    --delay: #{$i * $short-animation-duration-unit}s;
  }
  .delay-#{$i},
  .delay-each-child > :nth-child(#{$i}),
  .delay-parent .delay:nth-child(#{$i}) {
    // animation-delay: #{$i * $animation-duration-unit}s;
    --delay: #{$i * $animation-duration-unit}s;
  }
}

// Animations

$animation-duration: 0.2s;
.effect {
  animation-fill-mode: both;
  animation-duration: var(--duration);
  animation-delay: var(--delay);
}

.fade-in {
  animation-name: fadeIn;
  @extend .effect;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-up {
  animation-name: fadeInUp;
  @extend .effect;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in-left {
  animation-name: fadeInLeft;
  @extend .effect;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.fade-in-right {
  animation-name: fadeInRight;
  @extend .effect;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.slide-in-right {
  animation-name: slideInRight;
  @extend .effect;
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.fade-in-grow {
  animation-name: fadeInGrow;
  @extend .effect;
}
@keyframes fadeInGrow {
  0% {
    opacity: 0;
    transform: scale(0.01);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in-grow-up {
  animation-name: fadeInGrowUp;
  @extend .effect;
  transform-origin: bottom;
}
@keyframes fadeInGrowUp {
  from {
    opacity: 0;
    transform: scale(0.1) translateX(-10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateX(0px);
  }
}

.float {
  animation-name: float;
  --duration: 8s;
  @extend .effect;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
@keyframes float {
  0% {
    transform: translatey(0px) rotate(5deg);
  }
  25% {
    transform: translatey(-5px) rotate(-5deg);
  }
  50% {
    transform: translatey(0) rotate(5deg);
  }
  75% {
    transform: translatey(5px) rotate(-5deg);
  }
  100% {
    transform: translatey(0px) rotate(5deg);
  }
}

.tumbleweed-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: var(--footer-height);
  width: 100%;
  overflow: hidden;
  min-height: 250px;
}

.tumbleweed {
  animation-name: tumbleweed;
  --duration: 5s;
  @extend .effect;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  position: absolute;
  left: -25%;
}

@keyframes tumbleweed {
  0% {
    left: -25%;
    transform: translate(0, 85px) rotate(0deg);
  }
  12% {
    left: -25%;
    transform: translate(0, 0px) rotate(180deg);
  }
  25% {
    transform: translate(0, 85px) rotate(360deg);
  }
  38% {
    transform: translate(0, 0px) rotate(540deg);
  }
  50% {
    transform: translate(0, 85px) rotate(720deg);
  }
  62% {
    transform: translate(0, 0px) rotate(900deg);
  }
  75% {
    transform: translate(0, 85px) rotate(1080deg);
  }
  88% {
    transform: translate(0, 0px) rotate(1260deg);
    left: 125%;
  }
  100% {
    left: 125%;
    transform: translate(0, 85px) rotate(1440deg);
  }
}

// Hover effects

.hover-icon {
  .icon-wrap {
    position: relative;
    svg,
    .icon {
      transition: opacity 0.1s, transform 0.2s;
    }
    .main-icon {
      opacity: 1;
    }
    .hovered-icon {
      opacity: 0;
      position: absolute;
      inset: 0;
      margin: auto;
    }
  }
  &:hover,
  &:active,
  &.active {
    .main-icon {
      opacity: 0;
      transform: rotate(180deg);
    }
    .hovered-icon {
      opacity: 1;
      transform: rotate(180deg);
    }
  }
}
