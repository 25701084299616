.card {
  --bs-card-title-color: var(--bs-secondary);
}
.card-header,
.card-footer {
  #{$headings} {
    &:only-child {
      margin-bottom: 0;
    }
  }
}
.thumbnail-card {
  .card-footer {
    border-top-color: transparent;
    transition: background 0.2s, color 0.2s;
    color: $dark;
  }
  &:hover {
    .card-footer {
      background: $primary;
      color: $light;
    }
    .card-body,
    .card-title,
    .card-footer * {
      color: inherit;
    }
  }
}
