.customer-details {
  @include media-breakpoint-up(xl) {
    min-width: 250px;
  }
}
.action-col {
  min-width: 67px;
  @include media-breakpoint-up(md) {
    min-width: 122px;
  }
}
.tiny-label {
  font-weight: 700;
  color: var(--bs-gray-500);
  font-size: 75%;
}
.customer-list-item {
  .show-on-hover {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s;
  }
  &:hover {
    .show-on-hover {
      visibility: visible;
      opacity: 1;
    }
  }
}
