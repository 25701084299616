.list-page {
  .table-responsive {
    td {
      position: relative;
    }
    td > .table-cell-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-decoration: none;
      color: inherit;
      &:hover {
        color: inherit;
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.filter-menu {
  .dropdown-toggle:after {
    display: none;
  }
  .dropdown-menu {
    min-width: 0;
  }
  .dropdown-item {
    &.active:hover {
      text-decoration: line-through;
      opacity: 0.5;
    }
    &.dropdown-item.active,
    &.dropdown-item:active {
      background-color: unset;
      color: unset;
    }
  }
}

.no-results-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.no-results {
  img {
    width: 200px;
    max-width: 50%;
    height: auto;
    &.tumbleweed {
      width: 165px;
    }
  }
}
:root {
  --custom-row-gutter-x: 0.5em;
  --extra-padding: 0.5rem;
  .extra-card-padding {
    padding: var(--extra-padding);
  }
  @include media-breakpoint-up(lg) {
    --custom-row-gutter-x: 2%;
    --extra-padding: 2rem;
  }
}
.col-lg-down {
  @include media-breakpoint-down(lg) {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 175px;
  }
}
.list-layout {
  &.striped {
    --bs-list-group-border-color: transparent;
    --bs-list-group-active-border-color: transparent;
    --bs-list-group-bg: var(--bs-gray-100);
    --bs-list-group-action-hover-bg: #d3dbdf;
    .list-group-item {
      transition: background 0.2s, color 0.2s;
      &:nth-child(odd):not(:hover):not(.active):not(.list-layout-header) {
        background-color: #eef0f2;
      }
    }
  }
}
