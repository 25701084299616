// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Utilility classes
$amounts: (
  10: 10%,
  25: 25%,
  50: 50%,
  75: 75%,
);

// Hover opacity
.hover-opacity {
  opacity: 0;
  transition: opacity 0.2s;
}
.hover-parent:hover .hover-opacity,
.hover-opacity:hover {
  opacity: 1;
}

@each $key, $value in $theme-colors {
  // Lighten / darken colors
  @each $amountKey, $amount in $amounts {
    .bg-#{$key}-tint-#{$amountKey} {
      background-color: tint-color($value, $amount);
    }
    .bg-#{$key}-shade-#{$amountKey} {
      background-color: shade-color($value, $amount);
    }
    .text-#{$key}-tint-#{$amountKey} {
      color: tint-color($value, $amount);
    }
    .text-#{$key}-shade-#{$amountKey} {
      color: shade-color($value, $amount);
    }
  }

  // Hover borders
  .hover-border-#{$key} {
    transition: border-color 0.2s;
  }
  .hover-parent:hover .hover-border-#{$key},
  .hover-border-#{$key}:hover {
    border-color: #{$value};
  }

  // Hover color
  .hover-#{$key} {
    transition: color 0.2s;
  }
  .hover-parent:hover .hover-#{$key},
  .hover-#{$key}:hover {
    color: #{$value};
  }

  // Hover background
  .hover-bg-#{$key} {
    transition: background 0.2s;
  }
  .hover-parent:hover .hover-bg-#{$key},
  .hover-bg-#{$key}:hover {
    background: #{$value};
  }

  @each $amountKey, $amount in $amounts {
    .hover-bg-#{$key}-bg-opacity-#{$amountKey},
    .hover-bg-#{$key}-tint-#{$amountKey},
    .hover-bg-#{$key}-shade-#{$amountKey} {
      transition: background 0.2s;
    }
    .hover-parent:hover .hover-bg-#{$key}-bg-opacity-#{$amountKey},
    .hover-bg-#{$key}-bg-opacity-#{$amountKey}:hover {
      background-color: rgba($value, $amount);
    }
    .hover-parent:hover .hover-bg-#{$key}-tint-#{$amountKey},
    .hover-bg-#{$key}-tint-#{$amountKey}:hover {
      background-color: tint-color($value, $amount);
    }
    .hover-parent:hover .hover-bg-#{$key}-shade-#{$amountKey},
    .hover-bg-#{$key}-shade-#{$amountKey}:hover {
      background-color: shade-color($value, $amount);
    }
  }
}

// Grays
@each $key, $value in $grays {
  .bg-gray-#{$key} {
    background-color: $value;
  }
  .text-gray-#{$key} {
    color: $value;
  }
}

// Smaller text sizes
$small-font-sizes: (
  25: 0.25em,
  30: 0.3em,
  35: 0.35em,
  40: 0.4em,
  45: 0.45em,
  50: 0.5em,
  55: 0.55em,
  60: 0.6em,
  65: 0.65em,
  70: 0.7em,
  75: 0.75em,
  80: 0.8em,
  85: 0.85em,
  90: 0.9em,
  95: 0.95em,
);
@each $key, $value in $small-font-sizes {
  .fs-#{$key} {
    font-size: $value;
  }
}

// Z-index
$z-index-amounts: (-1, 0, 1, 2, 3, 4, 5);
@each $value in $z-index-amounts {
  .z-index-#{$value} {
    z-index: $value;
  }
}

// Font weights
$fw-amounts: (100, 200, 300, 400, 500, 600, 700, 800, 900);
@each $value in $fw-amounts {
  .fw-#{$value} {
    font-weight: $value;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-help {
  cursor: help;
}

// Btn min widths
.min-w-5 {
  min-width: 5rem;
}
.min-w-8 {
  min-width: 8rem;
}
.min-w-10 {
  min-width: 10rem;
}

.color-dark {
  color: $color-contrast-dark;
}
.color-light {
  color: $color-contrast-light;
}

.gap-4-md-up {
  @include media-breakpoint-up(md) {
    gap: 1.5rem;
  }
}

// Transitions
.transition-color {
  transition: color 0.2s;
}
.transition-bg {
  transition: background 0.2s;
}

.inset-0 {
  inset: 0;
}

// Pointer events
.pointer-events-none {
  pointer-events: none;
}

// Alignment
.text-right {
  text-align: right;
}
