.header-top {
  transition: border-color 0.2s, padding-left 0.2s;
  .client-name {
    display: inline-block;
    vertical-align: middle;
    max-width: 50vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .menu-toggle-btn {
    height: var(--header-height);
  }
  &.fixed-top {
    height: var(--header-height);
    padding-left: var(--collapsed-menu-width);
    border-bottom: 6px solid var(--module-color);
    @include media-breakpoint-up(xl) {
      padding-left: var(--collapsed-menu-width);
      &.menuOpen {
        padding-left: var(--side-menu-width);
      }
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }
  }
  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .module-icon {
    @extend .h3;
    width: 1.15em;
    height: auto;
    margin-bottom: 0;
    margin-left: 0.25em;
    margin-right: 0.55em;
    color: var(--module-color, rgba(var(--bs-px-dark-rgb), 0.7));
  }
  .hover-icon {
    .module-icon {
      margin: 0;
    }
    &:hover {
      .module-icon {
        color: var(--dashboard);
      }
    }
  }
  &:not([data-bs-theme="dark"]) .breadcrumb {
    --bs-breadcrumb-divider-color: var(--bs-gray-500);
    --bs-link-color-rgb: var(--bs-px-dark-rgb);
    --bs-breadcrumb-item-active-color: rgba(var(--bs-px-dark-rgb), 0.4);
  }
  .breadcrumb-wrap {
    margin-bottom: -3px;
    margin-top: -4px;
  }
  h1 + .breadcrumb-wrap {
    margin-bottom: 6px;
    margin-top: -3px;
  }
}
.breadcrumb {
  --bs-breadcrumb-divider: "\276F";
  --bs-breadcrumb-font-size: 12px;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-divider-color: var(--bs-gray-600);
  margin-bottom: 0;
  .breadcrumb-item {
    a {
      text-decoration: none;
      transition: color 0.2s;
      span {
        text-decoration: underline;
      }
    }
    svg {
      font-size: 1.15em;
      vertical-align: text-bottom;
      opacity: 0.5;
    }
    .item-inner {
      display: inline-block;
    }
    .page-title {
      display: inline-block;
    }
    svg + .page-title {
      margin-left: 0.4em;
    }
    &:before {
      @extend .effect;
      @extend .fade-in;
      @extend .delay;
    }
  }
}

.colored-btn {
  --btn-bg: rgba(var(--module-color-rgb), 0.9);
  --bs-btn-color: rgba(255, 255, 255, 0.3);
  --bs-btn-border-color: rgba(255, 255, 255, 0.1);
  --bs-btn-hover-bg: var(--btn-bg);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-shadow-rgb: var(--module-color-rgb);
  --bs-btn-active-bg: var(--btn-bg);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--btn-bg);
  --bs-btn-disabled-border-color: var(--btn-bg);
  &:hover {
    --bs-btn-active-bg: var(--module-color);
  }
}

.square-btn {
  --bs-btn-padding-x: 0.65em;
}

.filter-btns {
  --bs-border-radius: 0;
  --bs-border-radius-lg: 0;
  .btn {
    @extend .square-btn;
    animation-fill-mode: both;
  }
}
