.loader {
  text-align: center;
  display: flex;
  width: 100%;
  // &.full-page {
  //   height: calc(100vh - var(--header-inner-height) - 22em);
  // }
  &.page-with-header {
    height: calc(100vh - var(--header-inner-height) - 22em);
  }
  img {
    display: block;
    max-width: 350px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      max-width: 50%;
    }
  }
}
